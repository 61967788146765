@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@font-face {
  font-family: "celias";
  src: url("./assets/font/Celias-Bold.woff") format("woff");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(/public/images/bg.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #3b4b7b;
  color: #fff;
  overflow-x: hidden;
  min-height: 100dvh;
}

body:has(.home-content) {
  background-image: url(/public/images/mobMachineBg.png);
}

body:has(.home-content) .layout-wrapper {
  background-image: url(/public/images/mobMachineBg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #3b4b7b;
}

body:has(.game-content) {
  background-image: url(/public/images/bgLight.png);
}

body:has(.final-home-content) {
  background-image: url(/public/images/finalImage.png);
}

body:has(.shop-page) {
  background-image: url(/public/images/shopNowMobImg.png);
}

body:has(.timeout-page) {
  background-size: cover;
}

body:has(.landing-vid) {
  background-image: url(/public/video/DoohIntroVid.mp4);
  /* background-size: cover; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bebas Neue", sans-serif;
  margin: 0;
}

p,
span {
  font-family: "celias";
  margin: 0;
}

img,
video {
  transition: all 200ms ease-out;
}

.App {
  position: relative;
  width: 100%;
}

.landing-vid {
  position: relative;
}

@media screen and (max-width: 991px) {
  body {
    background-size: 100% 100%;
  }
}
